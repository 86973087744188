<template>
<div>
    <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mb-1" >
            <vx-card title="Navigation">
                <div class="vx-row mb-6 mt-0" style="display:none">
                    <div class="vx-col w-full">
                        <vx-input-group class="mb-0" >
                            <vs-input  placeholder="Suche ..." v-model="search" name="userSearchTerm"  />
                        </vx-input-group>
                    </div>
                </div>
                <div>
                    <ul>
                        <vue-tree-navigation
                                :items="items" :defaultOpenLevel="1"
                        ></vue-tree-navigation>
                    </ul>
                </div>

            </vx-card>
        </div>

        <div class="vx-col w-full mb-1 md:w-3/4 docs-content markdown-body" >
            <vx-card>
                <router-link  :to="'/docs-edit/'+markupData.path" class="dd-right right float-right">
                    <vs-button color="success" type="filled" icon="edit"
                               size="small"
                               :to="'/docs-edit/'+markupData.path">Bearbeiten</vs-button>
                </router-link>
                <div v-if="markupData && markupData.header && markupData.header.title" class="header-title">

                    <h1>{{markupData.header.title}}</h1>

                </div>

                <div v-if="markupData && markupData.content" v-html="markupData.content" class="dynamic-content" @click="handleClicks">
                </div>

                <div v-if="markupData && markupData.filemtime" class="footer">
                    Letzte Änderung am: {{markupData.filemtime}}
                </div>
            </vx-card>
        </div>
    </div>
</div>

</template>
<script>
    import TreeNavigation from '../../components/vue-tree-navigation/components/TreeNavigation/TreeNavigation';
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table';
    import ApiService from "../../api";
    import moment from "moment";
    import staticOptions from "@/mixins/static/options";
    import axios from 'axios';
    import * as qs from "qs";
    import '@/assets/css/markdown-body.css'

    export default {
        components: {
            VueBootstrap4Table,
            'vue-tree-navigation': TreeNavigation
        },
        data() {
            return {
                search: '',
                markupData: [],
                items: [],
                defaultOpenLevel: 1
            }
        },
        created() {
        },
        mounted() {
            this.fetchMarkup();
            this.fetchMarkupNavigation();
        },
        computed: {
            transformed() {
                return { template: this.markupData.content }
            },
            filter() {

                return this.items.filter(topBranch => {
                    if (topBranch.children) {
                        return topBranch.children.filter(childBranch => {

                            return childBranch.name.toLowerCase().match(this.search.toLowerCase());
                        })
                    }

                    return topBranch.name.toLowerCase().match(this.search.toLowerCase())
                })
            }
        },
        watch: {
            $route(to, from) {
                if (to.path == "/docs") {
                    this.fetchMarkup("readme")
                } else {
                    this.fetchMarkup(to.path.replace("/docs", ""))
                }
            }
        },
        methods: {

            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            async fetchMarkup(path) {
                this.$vs.loading();
                if (!path) {
                    if (this.$route.params && this.$route.params.pathMatch) {
                        path = this.$route.params.pathMatch;
                    } else {
                        path = "readme";
                    }
                }

                ApiService.get('docs/' + path)
                    .then((response) => {
                        this.$vs.loading.close();
                        this.markupData = response.data;

                    }).then((response) => {
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        // this.$router.push({name: 'customers'});
                    })
            },
            async fetchMarkupNavigation() {

                ApiService.get('docs-navigation' )
                    .then((response) => {
                        this.$vs.loading.close();
                        this.items = response.data[0];
                    })
                    .catch((error) => {
                    })
            },
            async handleClicks(event) {
                // ensure we use the link, in case the click has been received by a subelement
                let {target} = event
                while (target && target.tagName !== 'A') target = target.parentNode
                // handle only links that occur inside the component and do not reference external resources
                if (target && target.matches(".dynamic-content a:not([href*='://'])") && target.href) {
                    // some sanity checks taken from vue-router:
                    // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                    const {altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented} = event
                    // don't handle with control keys
                    if (metaKey || altKey || ctrlKey || shiftKey) return
                    // don't handle when preventDefault called
                    if (defaultPrevented) return
                    // don't handle right clicks
                    if (button !== undefined && button !== 0) return
                    // don't handle if `target="_blank"`
                    if (target && target.getAttribute) {
                        const linkTarget = target.getAttribute('target')
                        if (/\b_blank\b/i.test(linkTarget)) return
                    }
                    // don't handle same page links/anchors
                    const url = new URL(target.href)
                    const to = url.pathname
                    if (window.location.pathname !== to && event.preventDefault) {
                        event.preventDefault()

                        const navigationResult = await this.$router.push(to)

                        if (navigationResult) {
                            console.log("1")
                            this.fetchMarkup()
                        } else {

                        }
                    }

                }
            }
        },
    }
</script>
<style lang="scss">

    .NavigationLevel.NavigationLevel--level-0 > ul > li {
        padding-left: 0;
    }

    .NavigationLevel__parent, .NavigationLevel__parent .NavigationItem > a   {
        font-weight: 600;
    }

    .NavigationToggle__icon {
        padding: 2px;
        border-color: #e55d4b;
        top: 1px;
        position: relative;
    }
    .TreeNavigation .NavigationItem > a {
        color:grey
    }

    .docs-content {
        .header-title {
            margin-bottom: 30px;
            border-bottom: 1px solid #efefef;
            > h1 {
                margin: 0;
                font-size: 2em;
            }
        }
        .footer {
            margin-top: 30px;
            border-top: 1px solid #efefef;

        }
    }

</style>